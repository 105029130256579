<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="12.846"
    height="12.846"
    viewBox="0 0 12.846 12.846"
    class="inline-block"
  >
    <g id="Cancel" transform="translate(-25.577 -63.577)">
      <line
        id="Line_255"
        data-name="Line 255"
        x2="10.018"
        y2="10.017"
        transform="translate(26.991 64.991)"
        fill="none"
        stroke="#303d43"
        stroke-linecap="round"
        stroke-width="2"
      />
      <line
        id="Line_256"
        data-name="Line 256"
        x1="10.018"
        y2="10.017"
        transform="translate(26.991 64.991)"
        fill="none"
        stroke="#303d43"
        stroke-linecap="round"
        stroke-width="2"
      />
    </g>
  </svg>
</template>
