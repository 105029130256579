import { ref, watch } from 'vue';

export const useSwipe = (el, options) => {
  options = { directional_threshold: 10, swipe_threshold: 0, ...options };

  const touchStartX = ref(0);
  const touchEndX = ref(0);
  const touchStartY = ref(0);
  const touchEndY = ref(0);
  const touchableElement = ref(null);

  const onTouchStart = (event) => {
    touchStartX.value = event.changedTouches[0].screenX;
    touchStartY.value = event.changedTouches[0].screenY;
  };

  const onTouchEnd = (event) => {
    touchEndX.value = event.changedTouches[0].screenX;
    touchEndY.value = event.changedTouches[0].screenY;
    handleGesture(event);
  };

  watch(
    () => el.value,
    (val) => {
      if (val) {
        touchableElement.value = el.value;
        touchableElement.value.addEventListener('touchstart', onTouchStart);
        touchableElement.value.addEventListener('touchend', onTouchEnd);
      } else {
        touchableElement?.value.removeEventListener('touchstart', onTouchStart);
        touchableElement?.value.removeEventListener('touchend', onTouchEnd);
      }
    }
  );

  const onSwipeLeft = [];
  const onSwipeRight = [];
  const onSwipeUp = [];
  const onSwipeDown = [];
  const onTap = [];

  const addEventListener = (arr, callback) => {
    arr.push(callback);
  };

  const handleGesture = (event) => {
    if (
      touchEndX.value < touchStartX.value &&
      Math.max(touchStartY.value, touchEndY.value) -
        Math.min(touchStartY.value, touchEndY.value) <
        options.directional_threshold
    ) {
      onSwipeLeft.forEach((callback) => callback(event));
    }

    if (
      touchEndX.value > touchStartX.value &&
      Math.max(touchStartY.value, touchEndY.value) -
        Math.min(touchStartY.value, touchEndY.value) <
        options.directional_threshold
    ) {
      onSwipeRight.forEach((callback) => callback(event));
    }

    if (
      touchEndY.value < touchStartY.value &&
      Math.max(touchStartX.value, touchEndX.value) -
        Math.min(touchStartX.value, touchEndX.value) <
        options.directional_threshold
    ) {
      if (touchStartY.value - touchEndY.value > options.swipe_threshold) {
        onSwipeUp.forEach((callback) => callback(event));
      }
    }

    if (
      touchEndY.value > touchStartY.value &&
      Math.max(touchStartX.value, touchEndX.value) -
        Math.min(touchStartX.value, touchEndX.value) <
        options.directional_threshold
    ) {
      if (touchEndY.value - touchStartY.value > options.swipe_threshold) {
        onSwipeDown.forEach((callback) => callback(event));
      }
    }

    if (touchEndY.value === touchStartY.value) {
      onTap.forEach((callback) => callback(event));
    }
  };

  return {
    onSwipeLeft: (callback) => addEventListener(onSwipeLeft, callback),
    onSwipeRight: (callback) => addEventListener(onSwipeRight, callback),
    onSwipeUp: (callback) => addEventListener(onSwipeUp, callback),
    onSwipeDown: (callback) => addEventListener(onSwipeDown, callback),
    onTap: (callback) => addEventListener(onTap, callback)
  };
};
