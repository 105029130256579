export default {
  created(el, binding) {
    el._keydownCallback = (event) => {
      if (event.key == binding.arg) {
        binding.value();
      }
    };
    document.addEventListener('keydown', el._keydownCallback);
  },
  beforeUnmount(el) {
    document.removeEventListener('keydown', el._keydownCallback);
    delete el._keydownCallback;
  }
};
